.breadcrumbItemWrapper {
  display: inline-flex;
  overflow: hidden;
  list-style-type: none;
  cursor: default;
}

.breadcrumbItemWrapper.disabled {
  cursor: not-allowed;
}
