@import "../../styles/typography";

@include create-text-classes;

p:first-of-type.text {
  margin-block: 0;
}

p.text + p {
  margin-block-start: var(--spacing-large);
  margin-block-end: 0;
}

