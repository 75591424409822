@mixin focus-style-inset($focus-radius: 4px, $shadow-depth: 3px) {
  &:focus-visible,
  &.focus-visible {
    @include focus-style-css-inset($focus-radius, $shadow-depth);
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
}

@mixin focus-style-on-primary($focus-radius: 4px) {
  &:focus-visible,
  &.focus-visible {
    @include focus-style-css-on-primary($focus-radius);
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
}

@mixin focus-style-base($focus-radius: 4px) {
  outline: none;
  z-index: 11;
  border-radius: $focus-radius;
}

@mixin focus-style-css($focus-radius: 4px, $shadow-depth: 3px) {
  @include focus-style-base($focus-radius);

  box-shadow: 0 0 0 $shadow-depth hsl(209deg 100% 50% / 50%), 0 0 0 1px var(--primary-hover-color) inset;
}

@mixin focus-style-css-inset($focus-radius: 4px, $shadow-depth: 3px) {
  @include focus-style-base($focus-radius);

  box-shadow: 0 0 0 $shadow-depth hsl(209deg 100% 50% / 50%) inset, 0 0 0 1px var(--primary-hover-color) inset;
}

@mixin focus-style-css-on-primary($focus-radius: 4px) {
  @include focus-style-base($focus-radius);

  box-shadow: 0 0 0 3px var(--text-color-on-primary-with-opacity), 0 0 0 1px var(--text-color-on-primary) inset;
}
