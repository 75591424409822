@mixin pop-elastic() {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.2);
  }
  66% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
