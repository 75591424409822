@use "sass:string";
@import "~monday-ui-style/dist/mixins";
@import "~monday-ui-style/dist/functions";

@mixin create-text-classes() {
  @include create-typography-classes($textClasses, text);
}

@mixin create-title-classes() {
  @include create-typography-classes($headingClasses, null);
}

@mixin create-typography-classes($classes, $token-prefix) {
  @each $class-prefix, $weight-types in $classes {
    @each $weight-type in $weight-types {
      $class-name: camelize(#{$class-prefix}-#{$weight-type});

      .#{$class-name} {
        // if class is a heading class
        @if str-slice($class-prefix, 1, 1) == "h" {
          @include vibe-heading($class-prefix, $weight-type);
        } @else {
          @include vibe-text($class-prefix, $weight-type);
        }
      }
    }
  }
}

$headingClasses: (
  "h1": (
    "bold",
    "normal",
    "light"
  ),
  "h2": (
    "bold",
    "normal",
    "light"
  ),
  "h3": (
    "bold",
    "normal",
    "light"
  )
);

$textClasses: (
  "text1": (
    "bold",
    "medium",
    "normal"
  ),
  "text2": (
    "bold",
    "medium",
    "normal"
  )
);

@mixin smoothing-text {
  -webkit-font-smoothing: var(--font-smoothing-webkit);
  -moz-osx-font-smoothing: var(--font-smoothing-moz);
}

@mixin label-text {
  @include smoothing-text;
  @include vibe-text(text2, normal);
}

// Caption / Subtext (small)
@mixin font-caption {
  @include vibe-text(text2, normal);
  @include smoothing-text;
}

@mixin font-input {
  @include vibe-text(text2, normal);
}

@mixin heading-padding {
  padding: 0 4px;
}
