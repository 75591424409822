.infix {
  font-size: 14px;
  font-family: var(--font-family);
  flex: 0 0 auto;
}

.txt {
  white-space: nowrap;
}

.prefix {
  margin-right: var(--spacing-small);
}

.postfix {
  margin-left: var(--spacing-small);
}
