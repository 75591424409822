$circle-default-size: 40px;
$circle-radius: 50%;
$rectangle-default-size: 160px;

// Empty state heights
$h1-empty-state-height: 32px;
$h2-empty-state-height: 24px;
$h3-empty-state-height: 24px;
$h4-empty-state-height: 21px;
$h5-empty-state-height: 16px;
$h6-empty-state-height: 12px;
$small-empty-state-height: 12px;
