.galleryHeader {
  margin: 0 var(--spacing-small);
  display: flex;
  align-items: center;

  .galleryHeaderDot {
    margin-right: var(--spacing-small);
    &:last-child {
      margin-right: 0;
    }
  }
}
