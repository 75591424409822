@mixin pop-in-emphasized() {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
