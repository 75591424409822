.comboboxItemContainer {
  display: flex;
  flex: auto;
  width: 100%;
  background-color: var(--secondary-background-color);
}

.dividerContainer {
  position: relative;
  width: 95%;
}

.divider {
  position: absolute;

  // 16 px are the margin of the dialog and the scroll size
  width: calc(100% + 16px);
  margin-left: -12px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 20;
}