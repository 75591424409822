@import "../../styles/states";
@import "../../styles/typography";

.listItem {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  @include focus-style-inset();
}

.listItem:hover {
  background-color: var(--primary-background-hover-color);
}

.disabled {
  cursor: not-allowed;
}

.disabled:hover {
  background-color: transparent;
}

.selected {
  background-color: var(--primary-selected-color);
}

.selected:hover {
  background-color: var(--primary-selected-color);
}

.small {
  height: 32px;
}

.medium {
  height: 40px;
}

.large {
  height: 48px;
}
