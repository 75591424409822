.navigationItemContainer {
  line-height: 36px;
  font-size: 28px;
  color: var(--icon-color);
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
}
