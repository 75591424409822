.datePickerHeader {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  .button {
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    margin-left: 4px;
    cursor: pointer;

    .buttonContent {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      transition: all 0.1s ease;
      color: var(--primary-text-on-secondary-color, true);
      &:hover {
        background-color: var(--primary-background-hover-on-secondary-color);
        text-decoration: none;
      }
    }
  }
}
