@import "~monday-ui-style/dist/mixins";

.thumb {
  position: absolute;
  border: 3px solid;
  border-radius: 50%;
  border-color: var(--primary-color);
  background-color: var(--primary-background-color);
  font-size: 14px;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform var(--motion-productive-long) var(--motion-timing-enter);
  font-family: var(--font-family);
}

.dragging {
  cursor: grabbing !important;
  transform: translate(-50%, -50%) scale(1.33, 1.33);
  transform-origin: center center;
  transition: transform var(--motion-productive-long) var(--motion-timing-enter);
  box-shadow: var(--box-shadow-small);
}
.focused {
  border-color: var(--primary-hover-color);
  cursor: grab;
  box-shadow: var(--box-shadow-small);
}

// thumb (for NOT-disabled)
.notDisabledThumb {
  @include focus-style(50%);
  &:hover {
    border-color: var(--primary-hover-color);
    cursor: grab;
    box-shadow: var(--box-shadow-small);
  }
}

.positive {
  border-color: var(--positive-color);
}

.positive.notDisabledThumb {
  &:hover {
    border-color: var(--positive-color-hover);
  }
}

.negative {
  border-color: var(--negative-color);
}

.negative.notDisabledThumb {
  &:hover {
    border-color: var(--negative-color-hover);
  }
}

.small {
  height: 16px;
  width: 16px;
}

.medium {
  height: 24px;
  width: 24px;
}

.large {
  height: 24px;
  width: 24px;
}

.label {
  bottom: calc(100% + 8px);
  left: 50%;
  max-width: 50px;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  transform: translate(-50%, 0);
  white-space: nowrap;
}
