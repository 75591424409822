@tailwind base;
@tailwind components;
@tailwind utilities;


/*
 * TheTinyMCE toolbar floats as !important with a very high zindex. This causes problems with popover elements from dropsdowns and info modals.
 * Adding a hack to force the toolbar to be behind the popover elements
*/
.tinymce_toolbar_hack div {
  z-index: 10;
}