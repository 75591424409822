@import "../../styles/typography";
$avatar-icon-large: 28px;
$avatar-icon-medium: 18px;
$avatar-icon-small: 12px;
$font-size-avatar-small: 12px;

.contentImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.contentText {
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.contentTextLarge {
  @include vibe-heading("h3", "normal");
  font-family: var(--font-family) !important;
}

.contentTextMedium {
  @include vibe-text(text1, normal);
  margin-top: 1px;
}

.contentTextSmall {
  margin-top: 1px;
  font-size: $font-size-avatar-small;
}

.contentIconLarge {
  height: $avatar-icon-large;
  width: $avatar-icon-large;
}

.contentIconMedium {
  height: $avatar-icon-medium;
  width: $avatar-icon-medium;
}

.contentIconSmall {
  height: $avatar-icon-small;
  width: $avatar-icon-small;
}
