.table {
  --row-size-medium: 40px;
  --row-size-large: 48px;

  border-radius: var(--border-radius-small);
  background-color: var(--primary-background-color);
  border: 1px solid var(--layout-border-color);
  overflow: auto;
  width: 100%;
  height: 100%;

  [role="rowgroup"] {
    [role="row"] {
      > [role="cell"] {
        border-bottom: 1px solid var(--layout-border-color);
      }
    }

    > [role="row"]:last-of-type {
      > [role="cell"] {
        border-bottom: none;
      }
    }
  }
}
