@import "~monday-ui-style/dist/mixins";
@import "../../../../styles/typography";
@import "../../../../styles/keyframes";

.indicator {
  @include vibe-text(text2, normal);
  color: var(--text-color-on-primary);
  display: flex;
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 16px;
}

.sizeCompactTextContainer {
  margin-left: var(--spacing-small);
  justify-content: center;
  align-content: center;
}

.textContainerTitle {
  width: 100%;
  margin-bottom: var(--spacing-xs);
}

.textContainerTitle .visuallyHidden {
  display: none;
}

.textContainerTitleText {
  @include vibe-text(text2, medium);
  color: var(--primary-text-color);
  margin-bottom: 2px;
}

.textContainerSubtitleText {
  width: 100%;
  @include vibe-text(text2, normal);
  color: var(--primary-text-color);
}
.sizeCompactTextContainerTitle {
  margin-bottom: 0px;
}

.textPlacementVertical {
  margin: 8px;
  flex-direction: column;
  flex: 1 1 0px;
}

.textPlacementVertical .textContainer {
  margin-left: 0;
  margin-top: var(--spacing-medium);
}

.textPlacementVertical .numberDividerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.sizeCompactTextContainerTitleText {
  display: block;
  @include vibe-text(text2, normal);
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.textPlacementVertical .numberDividerContainer .divider {
  background-color: var(--ui-border-color);
  margin: 16px 0 16px 16px;
  height: 1px;
  flex: 1 1 0;
}

.sizeCompactNumberDividerContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  &.statusActiveNumberDividerContainer {
    width: 40px;
  }
}

.indicator.clickable {
  cursor: pointer;
}

.withAnimation {
  animation: stepIndicatiorCirclePopElastic var(--motion-expressive-short);
}

/* New state enters */

.swapEnter {
  opacity: 0;
  transform: translateY(15px);
}

.swapEnterActive {
  position: relative;
  opacity: 1;
  transition: transform var(--motion-productive-long) var(--motion-timing-enter), opacity var(--motion-productive-short);
  transform: translateY(0);
}

/* Old state leaves */

.swapExit {
  position: relative;
  opacity: 1;
  transform: translateY(0);
}

.swapExitActive {
  position: relative;
  opacity: 0;
  transform: translateY(-15px);
  transition: transform var(--motion-productive-long) var(--motion-timing-enter), opacity var(--motion-productive-short);
}

.numberContainer {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
}

.numberContainerText {
  margin: auto;
  display: flex;
  align-items: center;
}

.numberContainerTextCheckIcon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.typePrimaryNumberContainer {
  background-color: var(--primary-color);
}

.typePrimaryNumberContainer:hover {
  background-color: var(--primary-hover-color);
}

.typeDangerNumberContainer {
  background-color: var(--negative-color);
}

.typeDangerNumberContainer:hover {
  background-color: var(--negative-color-hover);
}

.typeSuccessNumberContainer {
  background-color: var(--positive-color);
}

.typeSuccessNumberContainer:hover {
  background-color: var(--positive-color-hover);
}

.typeDarkNumberContainer {
  background-color: var(--inverted-color-background);
  color: var(--text-color-on-inverted);
}

.typeDarkNumberContainer:hover {
  background-color: var(--secondary-text-color);
}

.statusActiveNumberContainer {
  box-shadow: 0 0 0 4px var(--primary-background-color), 0 0 0 6px var(--primary-color);
}

.statusActiveNumberContainer.typeDangerNumberContainer {
  box-shadow: 0 0 0 4px var(--primary-background-color), 0 0 0 6px var(--negative-color);
}

.statusActiveNumberContainer.typeSuccessNumberContainer {
  box-shadow: 0 0 0 4px var(--primary-background-color), 0 0 0 6px var(--positive-color);
}

.statusActiveNumberContainer.typeDarkNumberContainer {
  box-shadow: 0 0 0 4px var(--primary-background-color), 0 0 0 6px var(--primary-text-color);
}

.sizeCompactNumberContainer {
  width: 28px;
  height: 28px;
}

.statusPendingNumberContainer {
  background-color: var(--primary-background-color);
  color: var(--primary-text-color);
  border-color: var(--ui-border-color) !important;
  border: 1px solid;
}

.statusPendingNumberContainer:hover {
  background-color: var(--primary-color);
  color: var(--text-color-on-primary);
  border: none;
}

.statusPendingNumberContainer.typeDangerNumberContainer:hover {
  background-color: var(--negative-color);
}

.statusPendingNumberContainer.typeSuccessNumberContainer:hover {
  background-color: var(--positive-color);
}

.statusPendingNumberContainer.typeDarkNumberContainer:hover {
  background-color: var(--text-color-on-inverted);
}

@include keyframe(step-indicatior-circle-pop-elastic) {
  @include pop-elastic();
}
